import React, { useEffect, useState, forwardRef, useRef } from "react";
import { Stage, Layer, Image as KonvaImage, Rect } from "react-konva";
import useImage from "use-image";
import StickerImage from "./StickerImage";
import skateboardMockupImage from "../asset/BaseUpdate.png";
import skateboardMask from "../asset/MaskUpdate.png";
import skateEdge from "../asset/Board.png";
import newBase from "../asset/skate/horizontal/0A.png";
import newBordo from "../asset/skate/horizontal/4.png";
import newMask from "../asset/skate/horizontal/3_PASS_THROUGH.png";
import newEffect from "../asset/skate/horizontal/1.png";
import newBaseBeanz from "../asset/skate/vertical/0.png";
import newBordoBeanz from "../asset/skate/vertical/4.png";
import newMaskBeanz from "../asset/skate/vertical/3_PASS_THROUGH.png";
import newEffectBeanz from "../asset/skate/vertical/1.png";
// import newBase2 from "../asset/_25-02-24_base_bordo.png";
// import newBordo2 from "../asset/_25-02-24_bordo.png";
// import newMask2 from "../asset/_25-02-24_base.png";
// import newEffect2 from "../asset/_25-02-24_effect.png";
// import newBase1 from "../asset/newBase.png";
// import newBordo1 from "../asset/newBordo.png";
// import newMask1 from "../asset/newMask.png";
import testEffect from "../asset/TestEffect.png";
import * as Konva from "konva";
import { localData } from "../data/localDataUtils";
import iconSearch from "../asset/search_icon-01.svg";
import iconZoom from "../asset/zoom-02-01.svg";

const SkateboardMockup3 = forwardRef(
  (
    {
      imageUrl,
      companionImageUrl,
      collection,
      selfie,
      companion,
      isLoadingMainImage,
      isLoadingCompanionImage,
      setIsLoadingCompanionImage,
      setIsLoadingMainImage,
      idTarget,
      activeStickers,
      setActiveStickers,
      beanzId,
      deleteEvent,
      openModalZoom,
      isMobileView,
    },
    ref
  ) => {
    console.log("URLLLLLL", imageUrl);
    const [userImage, userImageStatus] = useImage(imageUrl, "anonymous");
    const [companionImage, companionImageStatus] = useImage(
      companionImageUrl,
      "anonymous"
    );

    const [mockup, mockupStatus] = useImage(newBase);
    const [mockupBeanz, mockupBeanzStatus] = useImage(newBaseBeanz);
    const [mask, maskStatus] = useImage(newMask);
    const [maskBeanz, maskBeanzStatus] = useImage(newMaskBeanz);
    const [edge, edgeStatus] = useImage(newBordo);
    const [edgeBeanz, edgeBeanzStatus] = useImage(newBordoBeanz);
    const [shadow, shadowStatus] = useImage(newEffect);
    const [shadowBeanz, shadowBeanzStatus] = useImage(newEffectBeanz);
    const [backgroundColor, setBackgroundColor] = useState("");
    const isBeanzCollection = collection === "beanz";

    // Container dimensions
    const containerWidth = 550; //isMobileView ? 350 : 550;
    const containerHeight = 550; //isMobileView ? 350 : 550;

    // states for scaled dimensions
    const [scaledMockup, setScaledMockup] = useState(null);
    const [scaledMask, setScaledMask] = useState(null);
    const [scaledEdge, setScaledEdge] = useState(null);

    // Calculate scale factor to adapt image to container
    const fitImageToContainer = (img) => {
      const canvasWidth = containerWidth;
      const canvasHeight = containerHeight;
      const imageWidth = img.width;
      const imageHeight = img.height;

      // Calculate scale to fit the image into the canvas
      const widthScale = canvasWidth / imageWidth;
      const heightScale = canvasHeight / imageHeight;
      const scale = Math.min(widthScale, heightScale);

      // Calculate the size of the resized image
      const resizedWidth = imageWidth * scale;
      const resizedHeight = imageHeight * scale;

      // Calculate the offsets to center the resized image
      const offsetX = (canvasWidth - resizedWidth) / 2;
      const offsetY = (canvasHeight - resizedHeight) / 2;
      return {
        x: offsetX,
        y: offsetY,
        width: resizedWidth,
        height: resizedHeight,
      };
    };

    // adapt userImage to mask
    const fitUserImageWithinMask = (img, maskDimensions, focalPoint) => {
      const imageRatio = img.width / img.height;
      let maskRatio = maskDimensions.width / maskDimensions.height;

      // if (isBeanzCollection) {
      //   maskRatio = maskDimensions.height / maskDimensions.width;
      // }

      const baseRatio = containerWidth / 550;
      const margin = 25.9 * baseRatio; // try to use a margin in order to show the edge of the skate (but it'doesn't work..)
      const availableWidth = maskDimensions.width - margin * 2;
      const availableHeight = maskDimensions.height - margin * 2;

      let scale;
      let x, y;

      if (imageRatio > maskRatio) {
        // The width is the constraint
        scale = availableWidth / img.width;
        x = maskDimensions.x + margin;
        y =
          maskDimensions.y +
          (availableHeight - img.height * scale) / 2 -
          (focalPoint.y * scale - maskDimensions.height / 2) +
          margin;
      } else {
        // The height is the constraint
        scale = availableHeight / img.height;
        x =
          maskDimensions.x +
          (availableWidth - img.width * scale) / 2 -
          (focalPoint.x * scale - maskDimensions.width / 2) +
          margin;
        y = maskDimensions.y + margin;
      }

      return {
        x: Math.round(x + (isBeanzCollection ? 167 : 60) * baseRatio),
        y: Math.round(y + (isBeanzCollection ? 173 : -110) * baseRatio),
        width: Math.round(
          img.width * scale * (isBeanzCollection ? 0.275 : 0.6)
        ),
        height: Math.round(
          img.height * scale * (isBeanzCollection ? 0.275 : 0.6)
        ),
      };
    };

    // adapt companionImage to the mask
    const fitCompanionImageWithinMask = (img, maskDimensions, focalPoint) => {
      const imageRatio = img.width / img.height;
      const maskRatio = maskDimensions.width / maskDimensions.height;

      let scale;
      let x, y;

      const baseRatio = maskDimensions.width / 550;

      if (imageRatio > maskRatio) {
        // The width is the constraint
        scale = maskDimensions.width / img.width;
        x = maskDimensions.x;
        y =
          maskDimensions.y +
          (maskDimensions.height - img.height * scale) / 2 -
          (focalPoint.y * scale - maskDimensions.height / 2);
      } else {
        // The height is the constraint
        scale = maskDimensions.height / img.height;
        x =
          maskDimensions.x +
          (maskDimensions.width - img.width * scale) / 2 -
          (focalPoint.x * scale - maskDimensions.width / 2);
        y = maskDimensions.y;
      }

      return {
        x: x + 222 * baseRatio,
        y: y + 235.5 * baseRatio,
        width: img.width * scale * 0.197,
        height: img.height * scale * 0.197,
      };
    };

    const [scaledUserImage, setScaledUserImage] = useState(null);
    const [scaledCompanionImage, setScaledCompanionImage] = useState(null);

    // Funzione per convertire un valore RGB in esadecimale
    const rgbToHex = (r, g, b) => {
      const toHex = (c) => {
        const hex = c.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
      };
      return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
    };

    // Mappa dei colori da sostituire
    const colorReplacementMap = {
      "#b6b4af": "#bbbdb7",
      "#b7b5af": "#bbbdb7",
      "#b7b4ae": "#bbbdb7",
      "#9f9993": "#a19e99",
      "#9e9893": "#a19e99",
      "#9e9992": "#a19e99",
      "#9f9892": "#a19e99",
      "#d6d2cf": "#dfdfdd",
      "#d7d2ce": "#dfdfdd",
      "#e3e4e2": "#eff3f2",
      "#e2e5e2": "#eff3f2",
      "#e3e5e3": "#eff3f2",
    };

    // Funzione per ottenere il colore sostitutivo o lo stesso colore se non è necessario sostituire
    const getReplacementColor = (color) => {
      return colorReplacementMap[color] || color;
    };

    const getBeanColorById = (id) => {
      const beanColors = {
        17915: "#40a49b",
        842: "#40a49b",
        18511: "#40a49b",
        15094: "#9c77cb",
        2500: "#9c77cb",
        6819: "#6e4745",
        8841: "#c2424d",
        7560: "#c2424d",
        18679: "#c2424d",
        6469: "#423e4d",
        14594: "#423e4d",
        10213: "#423e4d",
        10502: "#0267bc",
        12646: "#0267bc",
        19460: "#0267bc",
        15171: "#e46844",
        1822: "#e5b338",
        //new
        9504: "#6e4745",
        8405: "#6e4745",
        9561: "#6e4745",
        1257: "#6e4745",
        3809: "#9c77cb",
        7620: "#9c77cb",
        11029: "#9c77cb",
        552: "#9c77cb",
        16801: "#9c77cb",
        5458: "#e46844",
        4090: "#e46844",
        17976: "#e46844",
        3286: "#e46844",
        1008: "#e46844",
        1529: "#dbc1b0",
        10635: "#dbc1b0",
        15258: "#dbc1b0",
        13933: "#dbc1b0",
        11192: "#c2424d",
        12452: "#c2424d",
        6747: "#c2424d",
        18644: "#c2424d",
        6954: "#c2424d",
        14461: "#406692",
        13365: "#406692",
        14754: "#0267bc",
        505: "#0267bc",
        12258: "#0267bc",
        18477: "#e5b338",
        13414: "#dbc1b0",
        18109: "#ffabcf",
        2233: "#4061a2",
        17012: "#4061a2",
      };

      return beanColors[id] || null;
    };
    useEffect(() => {
      if (mockupStatus === "loaded" && mockup) {
        setScaledMockup(fitImageToContainer(mockup));
        // if (!isBeanzCollection) {
        // } else {
        //   const rotatedMockup = {
        //     image: mockup,
        //     x: containerWidth / 2,
        //     y: containerHeight / 2,
        //     width: mockup.height,
        //     height: mockup.width,
        //     rotation: 90,
        //     offsetX: mockup.height / 2,
        //     offsetY: mockup.width / 2,
        //   };
        //   //setScaledMask(rotatedMockup);
        //   console.log(rotatedMockup);
        //   //console.log(scaledMockup)
        //   //setScaledMask(fitImageToContainer(rotatedMockup));
        //   setScaledMockup((prevState) => fitImageToContainer(rotatedMockup));
        // }
      }

      if (maskStatus === "loaded" && mask) {
        setScaledMask(fitImageToContainer(mask));
        // if (!isBeanzCollection) {
        // } else {
        //   const rotatedMask = {
        //     image: mask,
        //     rotation: 90,
        //     offsetX: mask.height / 2,
        //     offsetY: mask.width / 2,
        //     x: containerWidth / 2,
        //     y: containerHeight / 2,
        //     width: mask.height,
        //     height: mask.width,
        //   };
        //   //setScaledMask(rotatedMask);
        //   //console.log(rotatedMask)
        //   console.log(scaledMask);
        //   //setScaledMask(fitImageToContainer(rotatedMask));
        //   setScaledMask((prevState) => fitImageToContainer(rotatedMask));
        // }
      }

      if (edgeStatus === "loaded" && edge) {
        setScaledEdge(fitImageToContainer(edge));
        // if (!isBeanzCollection) {
        // } else {
        //   const rotatedEdge = {
        //     image: edge,
        //     rotation: 90,
        //     offsetX: edge.height / 2,
        //     offsetY: edge.width / 2,
        //     x: containerWidth / 2,
        //     y: containerHeight / 2,
        //     width: edge.height,
        //     height: edge.width,
        //   };
        //   //setScaledMask(rotatedMask);
        //   //console.log(rotatedMask)
        //   console.log(scaledEdge);
        //   //setScaledMask(fitImageToContainer(rotatedMask));
        //   setScaledEdge((prevState) => fitImageToContainer(rotatedEdge));
        // }
      }

      if (scaledMask && companionImage && !scaledCompanionImage) {
        const focalPoint2 = { x: 1000, y: 810 };
        setScaledCompanionImage(
          fitCompanionImageWithinMask(companionImage, scaledMask, focalPoint2)
        );
        localData.setCompanionImage(collection, companionImageUrl);
      }
    }, [
      mockup,
      mockupStatus,
      mask,
      maskStatus,
      userImage,
      companionImage,
      imageUrl,
      selfie,
      edge,
      edgeStatus,
      activeStickers,
    ]);

    useEffect(() => {
      const loadImage = (src, callback) => {
        const img = new window.Image();
        img.crossOrigin = "Anonymous";
        img.onload = () => callback(img);
        img.src = src;
      };

      if (scaledMask && userImage && !scaledUserImage) {
        if (!isBeanzCollection) {
          const focalPoint = { x: 3500, y: 1310 };
          loadImage(imageUrl, (img) => {
            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d");
            canvas.width = img.width;
            canvas.height = img.height;
            context.drawImage(img, 0, 0);

            context.rect(
              scaledMask.x,
              scaledMask.y,
              scaledMask.width,
              scaledMask.height
            );
            context.clip();

            // Pick up the color backgound
            const pixelData = context.getImageData(0, 0, 1, 1).data;
            let hexColor = rgbToHex(pixelData[0], pixelData[1], pixelData[2]);

            // Controlla se il colore deve essere sostituito
            hexColor = getReplacementColor(hexColor);

            // Usa il valore esadecimale per il background o altro
            setBackgroundColor(hexColor);
            //setBackgroundColor(`rgba(${pixelData[0]}, ${pixelData[1]}, ${pixelData[2]}, 1)`);

            setScaledUserImage(
              fitUserImageWithinMask(userImage, scaledMask, focalPoint)
            );
            localData.setImage(collection, imageUrl);
          });
        } else {
          const focalPoint = { x: 1050, y: 1200 };
          const specialBeanColor = getBeanColorById(idTarget);
          loadImage(imageUrl, (img) => {
            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d");
            canvas.width = img.width;
            canvas.height = img.height;
            context.drawImage(img, 0, 0);

            localData.setImage(collection, imageUrl);
            // Pick up the color backgound

            if (specialBeanColor && selfie) {
              console.log("SONO QUIIIIIIII!!!!");
              let hexColor = "";
              hexColor = `${specialBeanColor}`;
              setBackgroundColor(hexColor);
              console.log("SELFIE COLOR", hexColor);
            } else if (!specialBeanColor && selfie) {
              let x = 998.82; // Coordinate x dove vuoi campionare il colore
              let y = 1659.87; // Coordinate y dove vuoi campionare il colore
              const pixelData = context.getImageData(
                Math.round(x),
                Math.round(y),
                1,
                1
              ).data;
              let hexColor = rgbToHex(pixelData[0], pixelData[1], pixelData[2]);
              if (backgroundColor !== hexColor) {
                if (hexColor === "#4e4e4e") {
                  console.log("COLORE !=!=!==!", hexColor);
                  hexColor = "#f4ece0";
                } else if (hexColor === "#406692") {
                  hexColor = "#264a77";
                } else if (hexColor === "#6998dc") {
                  hexColor = "#4061a2";
                } else if (hexColor === "#66a5af") {
                  hexColor = "#58c0d2";
                } else if (hexColor === "#ffd75b") {
                  const newX = 101.43;
                  const newY = 1520.86;
                  console.log("SONO QUIII", newX, newY);
                  const newPixelData = context.getImageData(
                    Math.round(newX),
                    Math.round(newY),
                    1,
                    1
                  ).data;
                  hexColor = rgbToHex(
                    newPixelData[0],
                    newPixelData[1],
                    newPixelData[2]
                  );
                } else if (hexColor === "#f5ecdf") {
                  x = 1960.76;
                  y = 1088.31;
                  console.log("SONO QUIII", x, y);
                  const newPixelData = context.getImageData(
                    Math.round(x),
                    Math.round(y),
                    1,
                    1
                  ).data;
                  hexColor = rgbToHex(
                    newPixelData[0],
                    newPixelData[1],
                    newPixelData[2]
                  );
                } else if (hexColor === "#b3aca3") {
                  x = 998.82;
                  y = 791.33;
                  console.log("SONO QUIII", x, y);
                  const newPixelData = context.getImageData(
                    Math.round(x),
                    Math.round(y),
                    1,
                    1
                  ).data;
                  hexColor = rgbToHex(
                    newPixelData[0],
                    newPixelData[1],
                    newPixelData[2]
                  );
                } else if (hexColor === "#363636") {
                  x = 998.82;
                  y = 881.94;
                  const newPixelData = context.getImageData(
                    Math.round(x),
                    Math.round(y),
                    1,
                    1
                  ).data;
                  hexColor = rgbToHex(
                    newPixelData[0],
                    newPixelData[1],
                    newPixelData[2]
                  );
                }

                // Controlla se il colore deve essere sostituito
                hexColor = getReplacementColor(hexColor);

                setBackgroundColor(hexColor);
              }
            } else {
              const pixelData = context.getImageData(0, 0, 1, 1).data;
              let hexColor = rgbToHex(pixelData[0], pixelData[1], pixelData[2]);

              // Controlla se il colore deve essere sostituito
              hexColor = getReplacementColor(hexColor);

              setBackgroundColor(hexColor);
            }
            setScaledUserImage(
              fitUserImageWithinMask(userImage, scaledMask, focalPoint)
            );
          });
        }
      }
    }, [scaledMask, userImage]);

    // Handler per eliminare gli sticker al pulsante DELETE
    const [deletePressed, setDeletePressed] = useState(false);
    let deleteKey = false;
    const handleKeyDown = (event) => {
      if (deleteKey) return;

      const key = event.key;

      if (key === "Delete" || key === "Backspace") {
        deleteKey = true;
        setDeletePressed(deleteKey);
      }
    };
    const handleKeyUp = (event) => {
      if (!deleteKey) return;

      const key = event.key;
      if (key === "Delete" || key === "Backspace") {
        deleteKey = false;
        setDeletePressed(deleteKey);
      }
    };

    useEffect(() => {
      const storedStickers = localStorage.getItem("activeStickers");
      if (storedStickers) {
        setActiveStickers(JSON.parse(storedStickers));
      }
      document.addEventListener("keydown", handleKeyDown);
      document.addEventListener("keyup", handleKeyUp);

      return () => {
        document.removeEventListener("keydown", handleKeyDown);
        document.removeEventListener("keyup", handleKeyUp);
      };
    }, []);

    //useEffect to force conditions to show loader
    useEffect(() => {
      if (userImageStatus === "loaded") {
        setIsLoadingMainImage(false);
      }
      if (userImageStatus !== "loaded") {
        setIsLoadingMainImage(true);
      }
      if (companionImageStatus === "loaded") {
        setIsLoadingCompanionImage(false);
      }
      if (companionImageStatus !== "loaded") {
        setIsLoadingCompanionImage(true);
      }
    }, [userImageStatus, companionImageStatus]);

    if (!scaledMockup || !scaledMask || !scaledEdge) {
      return (
        <div
        // className="custom-loader"
        // style={{
        //   top: "50%",
        //   left: "50%",
        //   transform: "translate(-50%, -25%)",
        // }}
        ></div>
      );
    }

    return (
      <>
        <div className="relative flex flex-col justify-center">
          <Stage
            id="stage-canvas"
            width={containerWidth}
            height={containerHeight}
            className={`flex items-center ${isMobileView ? "scale-65" : ""}`}
            onMouseDown={(e) => {
              if (e.evt.button === 2) e.evt.preventDefault();
            }}
            onContextMenu={(e) => e.evt.preventDefault()}
          >
            <Layer>
              <KonvaImage
                image={isBeanzCollection ? mockupBeanz : mockup}
                x={scaledMockup.x}
                y={scaledMockup.y}
                width={scaledMockup.width}
                height={scaledMockup.height}
              />

              <Rect
                x={scaledMask.x}
                y={scaledMask.y}
                width={scaledMask.width}
                height={scaledMask.height}
                fill={backgroundColor}
                globalCompositeOperation="source-atop"
                // rotation={isBeanzCollection ? 90 : 0}
              />

              {scaledUserImage && (
                <>
                  <KonvaImage
                    image={userImage}
                    x={scaledUserImage.x}
                    y={scaledUserImage.y}
                    width={scaledUserImage.width}
                    height={scaledUserImage.height}
                    globalCompositeOperation="source-atop"
                  />
                </>
              )}
              {scaledCompanionImage && (
                <KonvaImage
                  image={companionImage}
                  x={scaledCompanionImage.x}
                  y={scaledCompanionImage.y}
                  width={scaledCompanionImage.width}
                  height={scaledCompanionImage.height}
                  globalCompositeOperation="source-atop"
                />
              )}
              {activeStickers.map((stickerObj, index) => (
                <StickerImage
                  key={index}
                  url={stickerObj.src}
                  collection={collection}
                  scaledMask={scaledMockup}
                  isBeanzCollection={isBeanzCollection}
                  activeStickers={activeStickers}
                  controlsActive={stickerObj.controlsActive}
                  setActiveStickers={setActiveStickers}
                  deleteFired={!deletePressed}
                  deleteEvent={deleteEvent}
                  scaleRatio={containerWidth / 550}
                />
              ))}
              <KonvaImage
                image={isBeanzCollection ? maskBeanz : mask}
                x={scaledMask.x}
                y={scaledMask.y}
                width={scaledMask.width}
                height={scaledMask.height}
                globalCompositeOperation="multiply"
                opacity={0.7}
                listening={false}
              />
              <KonvaImage
                image={isBeanzCollection ? shadowBeanz : shadow}
                x={scaledMockup.x}
                y={scaledMockup.y}
                width={scaledMockup.width}
                height={scaledMockup.height}
                globalCompositeOperation="difference"
                listening={false}
              />

              <KonvaImage
                image={isBeanzCollection ? edgeBeanz : edge}
                x={scaledEdge.x}
                y={scaledEdge.y}
                width={scaledEdge.width}
                height={scaledEdge.height}
                globalCompositeOperation="source-atop"
                listening={false}
              />
            </Layer>
          </Stage>
          {/* TEST IMMAGINE NON SU KONVA */}
          {/* {scaledUserImage && idTarget && (
            <div
              className={`absolute top-[${scaledUserImage.y}px] right-[${scaledUserImage.x}px]`}
            >
              {
                <img
                  src={imageUrl}
                  width={scaledUserImage.width}
                  height={scaledUserImage.height}
                ></img>
              }
            </div>
          )} */}
          {!isMobileView && (
            <button
              type="button"
              className={`absolute p-[0.8rem] top-[53%] ${
                isBeanzCollection
                  ? "right-1/3 translate-x-1/4 translate-y-[240px]"
                  : "-right-[2rem] translate-y-[3.5rem]"
              }`}
              onClick={openModalZoom}
              // toggleZoomModal
            >
              <img
                src={iconZoom}
                className="w-[1.6rem] h-[1.6rem] text-black"
              />
            </button>
          )}
          {!scaledUserImage && idTarget && (
            <div
              className="flex flex-row justify-center bg-transparent pb-5 absolute"
              style={{
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -25%)",
              }}
            >
              <div className="custom-loader"></div>
            </div>
          )}
          {(companion && selfie) ||
            (companion && isLoadingCompanionImage && beanzId && (
              <div
                className="flex flex-row justify-center bg-transparent pb-5 absolute"
                style={{
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -25%)",
                }}
              >
                <div className="custom-loader"></div>
              </div>
            ))}
        </div>
      </>
    );
  }
);

export default SkateboardMockup3;
