import { useState } from "react";
import detailStyle from "../styles/pages/ProductDetail.module.css";
import backIcon from "../asset/menu/Icon_back.svg";
import { Link } from "react-router-dom";

const ProductDetail = ({ product, productImages, addToCart }) => {
  const [selectedImage, setSelectedImage] = useState(0);
  const [selectedSize, setSelectedSize] = useState(product.size);
  const [selectedQuantity, setSelectedQuantity] = useState(1);

  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  const minSwipeDistance = 50;

  const onTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe) {
      setSelectedImage((prev) => {
        if (prev === productImages.length - 1) return 0;
        return selectedImage + 1;
      });
    } else if (isRightSwipe) {
      setSelectedImage((prev) => {
        if (prev === 0) return productImages.length - 1;
        return selectedImage - 1;
      });
    }
  };

  const handleDecrease = () => {
    if (selectedQuantity > 1) {
      setSelectedQuantity(selectedQuantity - 1);
    }
  };

  const handleIncrease = () => {
    setSelectedQuantity(selectedQuantity + 1);
  };

  const handleChange = (event) => {
    const value = parseInt(event.target.value, 10);
    if (value >= 1) {
      setSelectedQuantity(value);
    } else if (value < 1) {
      setSelectedQuantity(1);
    }
  };

  const handleProductAddToCart = () => {
    addToCart({
      ...product,
      quantity: selectedQuantity,
      size: selectedSize,
    });
  };

  return (
    <div className={detailStyle.detail__productDetail}>
      <div className={detailStyle.detail__productDetailLeft}>
        <Link to="/shop" className="absolute top-0 left-[-3rem] z-10">
          <img
            src={backIcon}
            alt="Back Icon"
            className="w-[1.7rem] h-[1.7rem] cursor-pointer z-10 transition-none"
          />
        </Link>
        <div
          className={detailStyle.detail__mainImageContainer}
          onTouchStart={onTouchStart}
          onTouchMove={onTouchMove}
          onTouchEnd={onTouchEnd}
        >
          <img
            src={productImages[selectedImage]}
            alt={product.name}
            className={detailStyle.detail__mainImage}
          />
        </div>
        <div className={detailStyle.detail__circleContainer}>
          {productImages.map((img, index) => (
            <div
              key={index}
              className={`${detailStyle.detail__circle} ${
                selectedImage === index ? detailStyle.detail__circleActive : ""
              }`}
              onClick={() => setSelectedImage(index)}
            ></div>
          ))}
        </div>
      </div>
      <div className={detailStyle.detail__productDetailRight}>
        <div className={detailStyle.detail__productDetailInfo}>
          <div className={detailStyle.detail__productDetailInfoWrapper}>
            <div className={detailStyle.detail__productDetailInfoHeader}>
              <h1 className={detailStyle.detail__productTitle}>
                {product.name}
              </h1>
              <p className={detailStyle.detail__productPrice}>
                ${product.price}
              </p>
            </div>
            <div className={detailStyle.detail__productDetailInfoActions}>
              <div className={detailStyle.detail__productDetailInfoActionsSize}>
                <label htmlFor="size">Size</label>
                <select
                  className={detailStyle.detail__selection}
                  id="size"
                  value={selectedSize}
                  onChange={(e) => setSelectedSize(e.target.value)}
                >
                  {/* {product.size &&
                    product.size.map((size, index) => (
                      <option key={index} value={size}>
                        {size}
                      </option>
                    ))} */}
                  <option value={product.size}>{product.size}</option>
                </select>
              </div>
              <div
                className={detailStyle.detail__productDetailInfoActionsQuantity}
              >
                <label htmlFor="quantity">Quantity</label>
                <div
                  className={
                    (detailStyle.detail__quantityInput,
                    detailStyle.detail__selection)
                  }
                >
                  <button
                    className={detailStyle.detail__quantityButton}
                    onClick={handleDecrease}
                  >
                    -
                  </button>
                  <input
                    type="number"
                    id="quantity"
                    value={selectedQuantity}
                    onChange={handleChange}
                    min={1}
                  />
                  <button
                    className={detailStyle.detail__quantityButton}
                    onClick={handleIncrease}
                  >
                    +
                  </button>
                </div>
              </div>
              <button
                className={detailStyle.detail__productQuickBuyButton}
              ></button>
              <button
                className={detailStyle.detail__productAddToCart}
                onClick={() => handleProductAddToCart()}
              ></button>
            </div>
            <pre className={detailStyle.detail__productDescription}>
              {product.description}
            </pre>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
