export const Modal = ({ closeFn, isVertical, children }) => {
  return (
    <div className="fixed left-0 top-0 z-[1055] h-full w-full overflow-y-hidden overflow-x-hidden outline-none bg-slate-900/30">
      <div className="pointer-events-none relative w-auto transition-all duration-300 ease-in-out  min-[576px]:mx-auto min-[576px]:mt-[3rem] min-[576px]:max-w-[90vw]">
        <div className="relative min-[576px]:shadow-[0_0.5rem_1rem_rgba(#000, 0.15)] pointer-events-auto relative flex flex-col justify-center rounded-[1.2rem] border-none max-h-[95vh] bg-white bg-clip-padding text-current shadow-lg outline-none">
          <button
            className="absolute top-[2rem] left-[2rem] z-[1056]"
            onClick={closeFn}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="h-[2.4rem] w-[2.4rem]"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <div
            className={`w-full h-full select-none ${
              isVertical ? "overflow-y-scroll" : "overflow-y-hidden"
            }`}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
