import React, { useEffect, useState } from "react";
import cartStyle from "../styles/components/Cart.module.css";

const servercall = process.env.REACT_APP_SERVER_BASE_URL;
const Cart = ({ cartProducts, visible, removeItem, isMobileView }) => {
  const [total, setTotal] = useState(0);
  const [checkoutBusy, setCheckoutBusy] = useState(false);

  const cart = document.querySelector("#cart") || null;

  // animate in out cart
  useEffect(() => {
    if (!cart) return;

    if (visible) {
      cart.setAttribute("data-visible", "");
    } else if (!visible && cart.hasAttribute("data-visible")) {
      cart.setAttribute("data-hiding", "");
      cart.addEventListener(
        "animationend",
        () => {
          cart.removeAttribute("data-hiding");
          cart.removeAttribute("data-visible");
        },
        { once: true }
      );
    }
  }, [cart, visible]);

  useEffect(() => {
    if (!cartProducts) return;

    const tot = cartProducts.reduce((acc, item) => {
      return acc + item.product.price * item.quantity;
    }, 0);

    setTotal(tot);
  }, [cartProducts]);

  const createLineItems = async () => {
    const prices = await fetch(`${servercall}prices`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    const pricesData = await prices.json();
    // console.log("Prices data", pricesData);
    // console.log("cartProduct data", cartProducts);

    const lineItems = cartProducts.map((item) => {
      return {
        price: pricesData.prices.find(
          (price) => price.product === item.product.stripeProductId
        )?.id,
        quantity: item.quantity,
      };
    });

    return lineItems;
  };

  const handleCheckout = async () => {
    setCheckoutBusy(true);

    const lineItems = await createLineItems();

    const res = await fetch(`${servercall}create-checkout-session`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ line_items: lineItems }),
    });
    const body = await res.json();

    if (body.error) {
      console.error("Error creating checkout session", body.error);
      setCheckoutBusy(false);
      return;
    } else if (body.url) {
      window.location.href = body.url;
    }
  };

  return (
    <div id="cart" className={`${cartStyle.cart}`}>
      <div className={cartStyle.cartWrapper}>
        <div className={cartStyle.cartHeader}>
          <h1>Your Shopping Cart</h1>
        </div>
        <div className={cartStyle.cartContent}>
          {cartProducts.map((item, index) => (
            <div className={cartStyle.cartItem} key={index}>
              <div className={cartStyle.cartItem__image}>
                <img
                  src={item.image}
                  alt="Product"
                  className="w-full h-full object-contain"
                />
              </div>
              <div className={cartStyle.cartItem__info}>
                <h2>{item.product.name}</h2>
                <p>Size: {item.size}</p>
                <p>Qty: {item.quantity}</p>
              </div>
              <div className={cartStyle.cartItem__price}>
                <p>${item.product.price}</p>
                <br></br>
                <button
                  className={cartStyle.cartItem__buttonRemove}
                  onClick={() => removeItem(item)}
                >
                  Remove
                </button>
              </div>
            </div>
          ))}
        </div>
        <div className={cartStyle.cartFooter}>
          <div className={cartStyle.cartFooter__total}>
            <p>Total</p>
            <p>${total}</p>
          </div>
          <p className={cartStyle.cartFooter__info}>
            Shipping calculated at checkout <br></br>{" "}
            <a
              className="underline underline-offset-2"
              href="https://twitter.com/messages/compose?recipient_id=1711767105664581632"
              target="_blank"
              rel="noreferrer"
            >
              Contact us
            </a>{" "}
            for a different payment method
          </p>
          <div className={cartStyle.cartFooter__checkout}>
            <button
              className={cartStyle.cartFooter__checkoutButton}
              disabled={checkoutBusy}
              onClick={handleCheckout}
            >
              Checkout
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cart;
