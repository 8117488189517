export const workshop_termsOfService = [
  {
    id: "product-disclaimer-section",
    title: "PRODUCT DISCLAIMER",
    description: [
      "Our handcrafted skateboard decks are made from 7-ply Canadian maple with heat-transferred printing on the bottom and laser-etched branding on the top. Featuring a standard popsicle shape, " +
        "dimensions of 31.75’’ x 8’’, a wheelbase of 14.25’’, and nose and tail measurements of 6.75’’ and 6.55’’, respectively.",
      "During the customisation process, the stickers you select are printed as part of the design itself. This integration means they are permanent and cannot be removed.",
      "If you are a pro skater and have a specific preference regarding sizing and concave let us know in advance.",
      "We do not assume responsibility for how our products are used. Customers are advised to conduct their own tests to ensure the safety and suitability of each product.",
      "Due to variations in color profiles between digital previews and print production, " +
        "we cannot guarantee precise color matches. " +
        "While we individually process each order and perform tests during manufacturing for quality, the final product's color may slightly differ from the displayed one.",
      "The nature of the manufacturing process may result in slight variations in product sizing. " +
        "We strive to maintain consistency but acknowledge potential differences from time to time. " +
        "While we uphold high-quality standards, occasional minor imperfections may occur on the graphics applied. " +
        "We assure you that these imperfections do not compromise the overall quality of the product.",
      "We plant a tree for every custom skateboard purchased through our website. " +
        'We carefully select and fund these initiatives through //{ "title": "TreeNation", "link":"https://tree-nation.com/it/profilo/kobo"}//.',
    ],
  },
  {
    id: "ip-and-licensing-section",
    title: "IP AND LICENSING",
    description: [
      "Upon purchasing our product, you agree to a one-off commercial license grant " +
        "concerning the intellectual property (IP) of the associated NFT. This license pertains " +
        "specifically to the product you are acquiring.",
      "As our website does not establish a wallet connection, we are unable to verify the " +
        "ownership of the digital asset intended for printing on our product. While we conduct " +
        "periodic manual verifications, we cannot guarantee the exhaustive validation of every " +
        "digital asset. You are required to utilise IPs only from assets that you personally own " +
        "or for which you have obtained a valid license.",
      "In the event of a breach of licensing terms, we assume no responsibility. We reserve " +
        "the right to provide customer information to third parties if required for any " +
        "investigation related to such breaches.",
    ],
  },
  {
    id: "shipping-and-return-policy-section",
    title: "SHIPPING AND RETURN POLICY",
    description: [
      "Due to the nature of our manufacturing process, we are unable to provide a specific " +
        "timeline for the completion and shipment of your order. However, we estimate a " +
        "delivery time of 4 to 6 weeks.",
      "As each item is custom-made, we do not accept returns. In the event that you receive defective products or " +
        "items that do not match your order, please contact us promptly.",
    ],
  },
  {
    id: "data-collection-section",
    title: "DATA COLLECTION",
    description: [
      "When completing the order form, you will be required to provide personal data such " +
        "as your name, collector profile, email, phone number and mailing address to facilitate the shipment " +
        "of the purchased product and for us to get in touch with you should any issue arise.",
      "Your data will not be shared with third parties, except in the instance where " +
        "ownership verification of the intellectual property (IP) is missed, and a claim for " +
        "breach of licensing terms arises. In such cases, information may be disclosed to " +
        "relevant parties involved in resolving the matter.",
      "All collected information will be stored and used solely for the purposes mentioned " +
        "above. We are committed to ensuring the security and confidentiality of your " +
        "personal data.",
      "By purchasing our products, you consent to all the above and agree to its terms.",
    ],
  },
];

export const shop_termsOfService = [
  {
    id: "product-disclaimer-section",
    title: "PRODUCT DISCLAIMER",
    description: [
      "Our skateboard decks are hand made from 7-ply Canadian maple with heat-transferred printing on the bottom and laser-etched branding on the top. " +
        "We stock standard popsicle shapes and sizes of 8 / 8.5 inches, featuring a mellow concave. " +
        "If you are a pro skater and have a specific preference regarding sizing and concave let us know in advance and we’ll try to do our best to accommodate.",
      "We do not assume responsibility for how our products are used. Customers are advised to conduct their own tests to ensure the safety and suitability of each product.",
      "Due to variations in colour profiles between digital previews and print production, we cannot guarantee precise colour matches. While we perform tests during manufacturing for quality, the final product's colour may slightly differ from the one displayed on our website.",
      "The nature of the manufacturing process may result in slight variations in product sizing. We strive to maintain consistency but acknowledge potential differences from time to time. While we uphold high-quality standards, occasional minor imperfections may occur on the graphics applied. We assure you that these imperfections do not compromise the overall quality of the product.",
      "We plant a tree for every skateboard purchased through our website. We carefully select and fund these initiatives through Tree Nation.",
    ],
  },
  {
    id: "custom-graphic-decks-section",
    title: "CUSTOM GRAPHIC DECKS",
    description: [
      "If you plan to order a custom graphic deck, please first ensure that the artwork you want printed is of high quality and size, ideally a 300dpi file. " +
        "You will not submit the file at the time of ordering; we will reach out to you within 24 hours to arrange the submission. " +
        "Afterward, we will work with you to confirm that the artwork meets our printing requirements and help you select the best placement for your design. ",
      "Please note that there is a possibility your artwork may not be suitable for printing, and it could require modifications or a complete redesign. ",
      "You are required to utilise artworks with IPs from assets that you personally own, not protected from copyrights or for which you have obtained a valid license. " +
        "In the event of a breach of licensing terms, we assume no responsibility. " +
        "We reserve the right to provide customer information to third parties if required for any investigation related to such breaches.",
      "If significant issues arise that cannot be resolved, we will not process your order, and you will receive a full refund.",
    ],
  },
  {
    id: "shipping-and-return-policy-section",
    title: "SHIPPING AND RETURN POLICY",
    description: [
      "We estimate a delivery time of 3 to 4 weeks. " +
        "Please note that the size of our products contributes to high shipping costs. While we cover a portion of these costs, you will encounter a flat international shipping rate at the checkout. " +
        "In the event that you receive defective products or items that do not match your order, please contact us promptly.",
      "We do not accept returns.",
    ],
  },
  {
    id: "data-collection-section",
    title: "DATA COLLECTION",
    description: [
      "When completing the order form, you will be required to provide personal data such as your name, email, phone number and mailing address to facilitate the shipment of the purchased product and for us to get in touch with you should any issue arise.",
      "Your data will not be shared with third parties and will be stored and used solely for the purposes mentioned above. We are committed to ensuring the security and confidentiality of your personal data.",
      "By purchasing our products, you consent to all the above and agree to its terms.",
    ],
  },
];
